import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import Field from './Field';

const Computers = ({ computers }) => (
  <>
    {Object.entries(computers).map(([key, computer], index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
          <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, fontWeight: 'bold' }}>
            {`Computer #${index + 1}${computer.brand ? ` - ${computer.brand}` : ''}`}
          </Typography>
          <Field label="Serial Number" value={computer.serialNumber} />
          <Field label="Type" value={computer.type} />
          <Field label="Network Device" value={computer.networkDevice} />
          <Field label="Network Device Port" value={computer.networkDevicePort} />
        </Paper>
      </Grid>
    ))}
  </>
);

export default Computers;
