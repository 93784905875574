import React from 'react';
import { Typography } from '@mui/material';

const Field = ({ label, value }) => {
  return value ? (
    <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
      {`${label}: ${value}`}
    </Typography>
  ) : null;
};

export default Field;
