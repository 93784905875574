import React from 'react';
import { Container, Typography, Box, CircularProgress, Grid } from '@mui/material';
import SignOut from '../auth/SignOut';
import mmaLogo from '../../assets/mma-logo.png';

const UnavailableNotice = (props) => {
    const { auth } = props;
    return (
        <Container maxWidth="sm" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 2, p: 3, mt: 5 }}>
                <Typography variant="h5" component="div" gutterBottom>
                    Service Unavailable
                </Typography>
                <Typography variant="body1" color="textSecondary">
                    This service is currently unavailable.
                    Please reach out to an administrator if you need further assistance.
                </Typography>
            </Box>

            {/* Logo Section */}
            <Grid item xs={12} style={{ marginTop: 'auto', marginBottom: 20 }}> 
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={mmaLogo} style={{ maxWidth: 100 }} alt="Logo" />
                </div>
            </Grid>

            {/* SignOut Button */}
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <SignOut auth={auth} />
                </div>
            </Grid>
        </Container>
    );
}


export default UnavailableNotice;
