import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Container } from '@mui/system';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';

import Month from './Month';
import SignOut from '../auth/SignOut';

import mmaLogo from '../../assets/mma-logo.png';

function Home({auth, profile}) {
  const [year, setYear] = useState("");
  const [color2019, setColor2019] = useState("#FFF");
  const [color2020, setColor2020] = useState("#FFF");
  const [color2021, setColor2021] = useState("#FFF");
  const [color2022, setColor2022] = useState("#FFF");
  const [color2023, setColor2023] = useState("#FFF");
  const [color2024, setColor2024] = useState("#FFF");
  const [currentUser, setCurrentUser] = useState(); // <-- initially undefined
  
  const handleMouseEnter = (year_selected) => {
    switch(year_selected) {
      case '2019': setColor2019("#FFCCCB"); break;
      case '2020': setColor2020("#FFCCCB"); break;
      case '2021': setColor2021("#FFCCCB"); break;
      case '2022': setColor2022("#FFCCCB"); break;
      case '2023': setColor2023("#FFCCCB"); break;
      case '2024': setColor2024("#FFCCCB"); break;
      default: console.log("Error, default case reached");
    }
  }

  const handleMouseLeave = (year_selected) => {
    switch(year_selected) {
      case '2019': if (year !== "2019") setColor2019("#FFF"); break;
      case '2020': if (year !== "2020") setColor2020("#FFF"); break;
      case '2021': if (year !== "2021") setColor2021("#FFF"); break;
      case '2022': if (year !== "2022") setColor2022("#FFF"); break;
      case '2023': if (year !== "2023") setColor2023("#FFF"); break;
      case '2024': if (year !== "2024") setColor2024("#FFF"); break;
      default: console.log("Error, default case reached");
    }
  }

  const handleSetYear = (year_selected) => {
    if (year) {
      switch(year) {
        case '2019': setColor2019("#FFF"); break;
        case '2020': setColor2020('#FFF'); break;
        case '2021': setColor2021("#FFF"); break;
        case '2022': setColor2022('#FFF'); break;
        case '2023': setColor2023('#FFF'); break;
        case '2024': setColor2024('#FFF'); break;
        default: console.log("Error, default case reached");
      }
    }

    setYear(year_selected);
    
    switch(year_selected) {
      case '2019': setColor2019("#FFCCCB"); break;
      case '2020': setColor2020('#FFCCCB'); break;
      case '2021': setColor2021("#FFCCCB"); break;
      case '2022': setColor2022('#FFCCCB'); break;
      case '2023': setColor2023('#FFCCCB'); break;
      case '2024': setColor2024('#FFCCCB'); break;
      default: console.log("Error, default case reached");
    }

  }

  if (!auth.uid) return <Routes><Route path="*" element={<Navigate replace to="/signin" />} /></Routes>

  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Grid container display="flex" justifyContent="center" alignItems="center" padding="10px" spacing={1}>
          <Grid item xs={6} lg={4}>
            <Paper variant="outlined" sx={{padding: 1}}>
              <Typography variant="h5" textAlign="center"> Select Year </Typography>
            </Paper>
            
            <Paper variant="outlined" sx={{padding: 2}}>
              <Stack spacing={1}>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: color2019}}
                    onMouseEnter={() => handleMouseEnter('2019')}
                    onMouseLeave={() => handleMouseLeave('2019')}
                    onClick={() => handleSetYear('2019')}>
                      2019
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: color2020}}
                    onMouseEnter={() => handleMouseEnter('2020')}
                    onMouseLeave={() => handleMouseLeave('2020')}
                    onClick={() => handleSetYear('2020')}>
                      2020
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: color2021}}
                    onMouseEnter={() => handleMouseEnter('2021')}
                    onMouseLeave={() => handleMouseLeave('2021')}
                    onClick={() => handleSetYear('2021')}>
                      2021
                    </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: color2022}}
                    onMouseEnter={() => handleMouseEnter('2022')}
                    onMouseLeave={() => handleMouseLeave('2022')}
                    onClick={() => handleSetYear('2022')}>
                      2022
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: color2023}}
                    onMouseEnter={() => handleMouseEnter('2023')}
                    onMouseLeave={() => handleMouseLeave('2023')}
                    onClick={() => handleSetYear('2023')}>
                      2023
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: color2024}}
                    onMouseEnter={() => handleMouseEnter('2024')}
                    onMouseLeave={() => handleMouseLeave('2024')}
                    onClick={() => handleSetYear('2024')}>
                      2024
                  </Typography>
                </Paper>

              </Stack>
            </Paper>
          </Grid>

          {year && (
            <Month year={year}/>
          )}

          <Grid item xs={12}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <img src={mmaLogo} style={{maxWidth: 100}}  />
            </div>
          </Grid>
          
          <Grid item xs={12}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <SignOut auth={auth} />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, null)(Home);