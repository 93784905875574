import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Route, Routes, Navigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grow from '@mui/material/Grow';

function Results({year, month, clients, projects}) {
  if (projects !== undefined && projects !== null) {
    return (
      <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
        <Paper variant="outlined" >
          <Paper variant="outlined" sx={{padding: 1}}>
            <Typography variant="h5" textAlign="center"> Results </Typography>
          </Paper>

          <Paper variant="outlined" sx={{padding: 2}}>
            <Stack spacing={1}>
              {Object.entries(projects).map(entry => {
                const id = entry[0];
                const project = entry[1];
                return (
                  <Paper key={id} elevation={3} >
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                      <Link
                        href={`/${project.path}/${id}`}
                        variant="h6"
                        textAlign="center"
                        underline="none"
                      >
                        {project.name}
                      </Link>
                    </Grid>
                  </Paper>
                )
              })}
            </Stack>
          </Paper>
        </Paper>
      </Grow>
    )
  } else {
    return (
      <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
        <Paper variant="outlined" >
          <Paper variant="outlined" sx={{padding: 1}}>
            <Typography variant="h5" textAlign="center"> Results </Typography>
          </Paper>

          <Paper variant="outlined" sx={{padding: 2}}>
            <Paper elevation={0}>
              <Typography variant="h6" textAlign="center"> No results found </Typography>
            </Paper>
          </Paper>
        </Paper>
      </Grow>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.firestore.data.clients,
    projects: state.firestore.data.queriedProjects,
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    return [
      {
        collection: 'projects',
        doc: props.year,
        subcollections: [{ collection: props.month}],
        storeAs: 'queriedProjects'
      },
      {
        collection: 'clients',
      }
    ]
  })
)(Results);