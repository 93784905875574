import React from 'react';
import { Modal, Box } from '@mui/material';
import ImageGallery from 'react-image-gallery';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  maxWidth: '90vw',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
};

const ImageGalleryModal = ({ open, onClose, images }) => (
  <Modal open={open} onClose={onClose}>
    <Box sx={modalStyle}>
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails={images.length > 1}
      />
    </Box>
  </Modal>
);

export default ImageGalleryModal;
