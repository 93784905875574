import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const DockingStations = ({ dockingStations }) => (
  <>
    {Object.entries(dockingStations || {}).map(([key, dockingStation], index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
          <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, fontWeight: 'bold' }}>
            {`Docking Station #${index + 1}${dockingStation.brand ? ` - ${dockingStation.brand}` : ''}`}
          </Typography>
        </Paper>
      </Grid>
    ))}
  </>
);

export default DockingStations;
