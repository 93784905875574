import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { ReactReduxFirebaseProvider, firebaseReducer, getFirebase } from 'react-redux-firebase';
import { createFirestoreInstance, firestoreReducer, reduxFirestore, getFirestore } from 'redux-firestore';
import thunk from 'redux-thunk';

/***** For render on auth ready *****/
import { useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
/*****  *****/

import AuthLoading from './components/auth/AuthLoading';

import { firebase, fbConfig } from './services/firebaseConfig';  // Updated import

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // instead of RealtimeDB
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

const store = createStore(
  rootReducer,
  compose(
      applyMiddleware(thunk.withExtraArgument({ getFirestore, getFirebase })),
      reduxFirestore(firebase, fbConfig)
  )
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
};

function AuthIsLoaded({ children }) {
  const auth = useSelector(state => state.firebase.auth)
  if (!isLoaded(auth)) return <AuthLoading />
  return children
};

ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <AuthIsLoaded>
          <App/>
        </AuthIsLoaded>
      </ReactReduxFirebaseProvider>
    </Provider>,
  document.getElementById('root')
);
