import { useState } from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Grow from '@mui/material/Grow';

import Results from './Results';

function Month({year}) {
  const [month, setMonth] = useState("");
  const [colorJanuary, setColorJanuary] = useState("#FFF");
  const [colorFebruary, setColorFebruary] = useState("#FFF");
  const [colorMarch, setColorMarch] = useState("#FFF");
  const [colorApril, setColorApril] = useState("#FFF");
  const [colorMay, setColorMay] = useState("#FFF");
  const [colorJune, setColorJune] = useState("#FFF");
  const [colorJuly, setColorJuly] = useState("#FFF");
  const [colorAugust, setColorAugust] = useState("#FFF");
  const [colorSeptember, setColorSeptember] = useState("#FFF");
  const [colorOctober, setColorOctober] = useState("#FFF");
  const [colorNovember, setColorNovember] = useState("#FFF");
  const [colorDecember, setColorDecember] = useState("#FFF");

  const handleMouseEnter = (month_selected) => {
    switch(month_selected) {
      case 'January': setColorJanuary("#FFCCCB"); break;
      case 'February': setColorFebruary("#FFCCCB"); break;
      case 'March': setColorMarch("#FFCCCB"); break;
      case 'April': setColorApril("#FFCCCB"); break;
      case 'May': setColorMay("#FFCCCB"); break;
      case 'June': setColorJune("#FFCCCB"); break;
      case 'July': setColorJuly("#FFCCCB"); break;
      case 'August': setColorAugust("#FFCCCB"); break;
      case 'September': setColorSeptember("#FFCCCB"); break;
      case 'October': setColorOctober("#FFCCCB"); break;
      case 'November': setColorNovember("#FFCCCB"); break;
      case 'December': setColorDecember("#FFCCCB"); break;
      default: console.log("Error, default case reached");
    }
  }

  const handleMouseLeave = (month_selected) => {
    switch(month_selected) {
      case 'January': if (month !== "January") setColorJanuary("#FFF"); break;
      case 'February': if (month !== "February") setColorFebruary("#FFF"); break;
      case 'March': if (month !== "March") setColorMarch("#FFF"); break;
      case 'April': if (month !== "April") setColorApril("#FFF"); break;
      case 'May': if (month !== "May") setColorMay("#FFF"); break;
      case 'June': if (month !== "June") setColorJune("#FFF"); break;
      case 'July': if (month !== "July") setColorJuly("#FFF"); break;
      case 'August': if (month !== "August") setColorAugust("#FFF"); break;
      case 'September': if (month !== "September") setColorSeptember("#FFF"); break;
      case 'October': if (month !== "October") setColorOctober("#FFF"); break;
      case 'November': if (month !== "November") setColorNovember("#FFF"); break;
      case 'December': if (month !== "December") setColorDecember("#FFF"); break;
      default: console.log("Error, default case reached");
    }
  }

  const handleSetMonth = (month_selected) => {
    if (month) {
      switch(month) {
        case 'January': setColorJanuary("#FFF"); break;
        case 'February': setColorFebruary("#FFF"); break;
        case 'March': setColorMarch("#FFF"); break;
        case 'April': setColorApril("#FFF"); break;
        case 'May': setColorMay("#FFF"); break;
        case 'June': setColorJune("#FFF"); break;
        case 'July': setColorJuly("#FFF"); break;
        case 'August': setColorAugust("#FFF"); break;
        case 'September': setColorSeptember("#FFF"); break;
        case 'October': setColorOctober("#FFF"); break;
        case 'November': setColorNovember("#FFF"); break;
        case 'December': setColorDecember("#FFF"); break;
        default: console.log("Error, default case reached");
      }
    }

    setMonth(month_selected);
    
    switch(month_selected) {
      case 'January': setColorJanuary("#FFCCCB"); break;
      case 'February': setColorFebruary("#FFCCCB"); break;
      case 'March': setColorMarch("#FFCCCB"); break;
      case 'April': setColorApril("#FFCCCB"); break;
      case 'May': setColorMay("#FFCCCB"); break;
      case 'June': setColorJune("#FFCCCB"); break;
      case 'July': setColorJuly("#FFCCCB"); break;
      case 'August': setColorAugust("#FFCCCB"); break;
      case 'September': setColorSeptember("#FFCCCB"); break;
      case 'October': setColorOctober("#FFCCCB"); break;
      case 'November': setColorNovember("#FFCCCB"); break;
      case 'December': setColorDecember("#FFCCCB"); break;
      default: console.log("Error, default case reached");
    }
  }
  
  return (
    <>
      <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
        <Grid item xs={6} lg={4}>
            <Paper variant="outlined" sx={{padding: 1}}>
              <Typography variant="h5" textAlign="center"> Select Month </Typography>
            </Paper>

            <Paper variant="outlined" sx={{padding: 2}}>
              <Stack spacing={1}>
                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorJanuary}}
                    onMouseEnter={() => handleMouseEnter("January")}
                    onMouseLeave={() => handleMouseLeave("January")}
                    onClick={() => handleSetMonth("January")}
                  >
                    January
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorFebruary}}
                    onMouseEnter={() => handleMouseEnter("February")}
                    onMouseLeave={() => handleMouseLeave("February")}
                    onClick={() => handleSetMonth("February")}
                  >
                    February
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorMarch}}
                    onMouseEnter={() => handleMouseEnter("March")}
                    onMouseLeave={() => handleMouseLeave("March")}
                    onClick={() => handleSetMonth("March")}
                  >
                    March
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorApril}}
                    onMouseEnter={() => handleMouseEnter("April")}
                    onMouseLeave={() => handleMouseLeave("April")}
                    onClick={() => handleSetMonth("April")}
                  >
                    April
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorMay}}
                    onMouseEnter={() => handleMouseEnter("May")}
                    onMouseLeave={() => handleMouseLeave("May")}
                    onClick={() => handleSetMonth("May")}
                  >
                    May
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorJune}}
                    onMouseEnter={() => handleMouseEnter("June")}
                    onMouseLeave={() => handleMouseLeave("June")}
                    onClick={() => handleSetMonth("June")}
                  >
                    June
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorJuly}}
                    onMouseEnter={() => handleMouseEnter("July")}
                    onMouseLeave={() => handleMouseLeave("July")}
                    onClick={() => handleSetMonth("July")}
                  >
                    July
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorAugust}}
                    onMouseEnter={() => handleMouseEnter("August")}
                    onMouseLeave={() => handleMouseLeave("August")}
                    onClick={() => handleSetMonth("August")}
                  >
                    August
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorSeptember}}
                    onMouseEnter={() => handleMouseEnter("September")}
                    onMouseLeave={() => handleMouseLeave("September")}
                    onClick={() => handleSetMonth("September")}
                  >
                    September
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorOctober}}
                    onMouseEnter={() => handleMouseEnter("October")}
                    onMouseLeave={() => handleMouseLeave("October")}
                    onClick={() => handleSetMonth("October")}
                  >
                    October
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorNovember}}
                    onMouseEnter={() => handleMouseEnter("November")}
                    onMouseLeave={() => handleMouseLeave("November")}
                    onClick={() => handleSetMonth("November")}
                  >
                    November
                  </Typography>
                </Paper>

                <Paper elevation={3}>
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{backgroundColor: colorDecember}}
                    onMouseEnter={() => handleMouseEnter("December")}
                    onMouseLeave={() => handleMouseLeave("December")}
                    onClick={() => handleSetMonth("December")}
                  >
                    December
                  </Typography>
                </Paper>
              </Stack>
            </Paper>
          </Grid>
      </Grow>

      {(year && month) && (
            <Grid item xs={12} lg={4}>
              <Results year={year} month={month}/>
            </Grid>
      )}
    </>
  )
}

export default Month;