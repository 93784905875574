import React, { useState } from 'react';
import { Typography, Container, Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createProject } from '../../store/actions/projectActions'
import ProjectDialog from './Modals/ProjectDialog';

function CreateProject({ selectedClient }) {
    const [open, setOpen] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [projectDate, setProjectDate] = useState('');
    const [projectStartTime, setProjectStartTime] = useState('');
    const dispatch = useDispatch();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false)

    const handleSubmit = () => {
        const projectDetails = {
            client: selectedClient,
            name: projectName,
            date: projectDate,
            startTime: projectStartTime,
        };
        dispatch(createProject(projectDetails));
        handleClose();
    };

    return (
        <Container maxWidth="sm" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ border: 1, borderColor: 'grey.300', borderRadius: 2, p: 3, mt: 5 }}>
                <Typography variant="h5" component="div" gutterBottom>
                    Create Project
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                    Click the button below to create a new project.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleOpen}
                    sx={{ mt: 2 }}
                >
                    Create Project
                </Button>
            </Box>

            <ProjectDialog
                open={open}
                onClose={handleClose}
                projectName={projectName}
                setProjectName={setProjectName}
                projectDate={projectDate}
                setProjectDate={setProjectDate}
                projectStartTime={projectStartTime}
                setProjectStartTime={setProjectStartTime}
                handleSubmit={handleSubmit}
            />
        </Container>
    );
}

export default CreateProject;
