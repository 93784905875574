import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import Loading from '../../assets/Loading.gif'
import Home from './Home';
import Dashboard from './Dashboard';
import UnavailableNotice from './UnavailableNotice';
import SetupAccountNotice from './SetupAccountNotice';

const RoleRedirect = (props) => {
    
  const { auth, profile } = props;

  if (!auth.uid) return <Navigate to='/signin' />
  
  switch (profile.role) {
    case 'admin':
    case 'supervisor':
    case 'client':
      return <Dashboard profile={profile} />

    case 'none':
      return <SetupAccountNotice />

    case 'test':
      return <Home />

    case 'tech':
    case 'lead':
    case 'deactivated':
      return <UnavailableNotice auth={auth} />

    default:
      return <img style={{marginTop: 20}} className="LoadingWheel" src={Loading} alt="loading..." />
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  }
}

export default connect(mapStateToProps, null)(RoleRedirect);