import React, { useState } from 'react';
import { Grid, Button, Paper, Typography, Box, Container } from '@mui/material';
import emptyPic from '../../../assets/no-pic-loaded.PNG';
import './BasicInfo.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGalleryModal from '../Modals/ImageGalleryModal';
import Computers from './Computers';
import Monitors from './Monitors';
import DockingStations from './DockingStations';
import Peripherals from './Peripherals';
import Issues from './Issues';

function BasicInfo({ users, workstation = {} }) {
  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [currentGallery, setCurrentGallery] = useState('');

  const handleGalleryOpen = (galleryType) => {
    setCurrentGallery(galleryType);
    setGalleryOpen(true);
  };
  const handleGalleryClose = () => setGalleryOpen(false);

  const imagesDisconnect = (workstation.pictures || [])
    .filter((pic) => pic)
    .map((pic) => ({
      original: pic,
      thumbnail: pic,
    }));

  const imagesReconnect = [
    ...Object.values(workstation.equipmentDamageAfter || {})
      .filter((damage) => damage.image) // Ensure there's an image key in the map
      .map((damage) => ({
        original: damage.image,
        thumbnail: damage.image,
      })),
    ...(workstation.reconnectPictures || [])
      .filter((picture) => picture) // Ensure the picture exists in the array
      .map((picture) => ({
        original: picture,
        thumbnail: picture,
      }))
  ]; 

  const UIDtoName = (userID) => (users?.[userID] ? `${users[userID].firstName} ${users[userID].lastName}` : null);
  const disconnectedBy = UIDtoName(workstation.documentBy);
  const reconnectedBy = UIDtoName(workstation.reconnect?.completedBy);
  const qaBy = UIDtoName(workstation.qa?.completedBy);

  const Field = ({ label, value }) => {
    return value ? (
      <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
        {`${label}: ${value}`}
      </Typography>
    ) : null;
  };

  const currentGalleryImages =
    currentGallery === 'disconnect' ? imagesDisconnect : imagesReconnect;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} display="flex" flexDirection="column" alignItems="center">
        <img
          src={workstation.pictures?.[0] || emptyPic}
          style={{ maxHeight: 250, maxWidth: '100%' }}
          alt="Workstation preview"
        />
        <Box sx={{ display: 'flex', gap: 1, marginTop: 2 }}>
          {imagesDisconnect.length > 0 && (
            <Button variant="outlined" onClick={() => handleGalleryOpen('disconnect')}>View Disconnect</Button>
          )}
          {imagesReconnect.length > 0 && (
            <Button variant="outlined" onClick={() => handleGalleryOpen('reconnect')}>View Reconnect</Button>
          )}
        </Box>

        <ImageGalleryModal 
          open={isGalleryOpen} 
          onClose={handleGalleryClose} 
          images={currentGalleryImages} 
        />

        <Issues issues={workstation.issues || {}} />

      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant="h5" textAlign="center" sx={{ marginBottom: 1, fontSize: { xs: '1.2rem', sm: '1.5rem' }, fontWeight: 'bold' }}>
          {workstation.username || ''}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Origin</Typography>
                <Field label="Building" value={workstation.originBuilding} />
                <Field label="Floor" value={workstation.originFloor} />
                <Field label="Desk" value={workstation.origin} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" sx={{ padding: 2 }}>
              <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>Destination</Typography>
                <Field label="Building" value={workstation.destinationBuilding} />
                <Field label="Floor" value={workstation.destinationFloor} />
                <Field label="Desk" value={workstation.destination} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {(disconnectedBy || reconnectedBy || qaBy) && (
              <Paper variant="outlined" sx={{ padding: 2, marginBottom: 1 }}>
                <Field label="Disconnected" value={disconnectedBy} />
                <Field label="Reconnected" value={reconnectedBy} />
                <Field label="QA" value={qaBy} />
              </Paper>
            )}
          </Grid>
        </Grid>

        <Container>
          <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
            <Computers computers={workstation.computers || {}} />
            <Monitors monitors={workstation.monitors || {}} />
            <DockingStations dockingStations={workstation.dockingStations || {}} />
            <Peripherals peripherals={workstation.peripherals || {}} />
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}

export default BasicInfo;
