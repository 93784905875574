import { Component } from 'react';
import { connect } from 'react-redux'
import { Route, Routes, Navigate } from 'react-router-dom';
import { createProject } from '../../store/actions/projectActions'

class CreateProjectOld extends Component {
  state = {
    title: '',
    content: '',
    startTime: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createProject(this.state);
  }
  render() {
    const { auth } = this.props;
    if (!auth.uid) return <Routes><Route path="*" element={<Navigate replace to="/signin" />} /></Routes>
    
    return (
      <div className="container">
        <form className="white" onSubmit={this.handleSubmit}>
          <h5 className="grey-text text-darken-3">Create a New Project</h5>
          <div className="input-field">
            <input type="text" id='title' onChange={this.handleChange} />
            <label htmlFor="title">Project Title</label>
          </div>
          <div className="input-field">
            <textarea id="content" className="materialize-textarea" onChange={this.handleChange}></textarea>
            <label htmlFor="content">Project Content</label>
          </div>
          <div className="input-field">
            <input type="datetime-local" id='startTime' onChange={this.handleChange} />
            <label htmlFor="startTime">Start Time</label>
          </div>

          <div className="input-field">
            <button className="btn pink lighten-1">Create</button>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createProject: (project) => dispatch(createProject(project))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProjectOld)