import React, { useState } from 'react';
import { Button, Box, Typography, Modal, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

function UploadModal({ uploadModalOpen, handleCloseUploadModal, handleFileUpload }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [optionalColumns, setOptionalColumns] = useState({
    originBuilding: false,
    originFloor: false,
    destinationBuilding: false,
    destinationFloor: false,
    notes: false,
  });

  const handleCheckboxChange = (event) => {
    setOptionalColumns({
      ...optionalColumns,
      [event.target.name]: event.target.checked,
    });
  };

  const createAndDownloadTemplate = () => {
    // Start with the default columns
    const worksheetData = [['username', 'origin', 'destination']];

    // Add the optional columns based on the user's selection
    const optionalColumnNames = Object.keys(optionalColumns).filter(column => optionalColumns[column]);
    worksheetData[0].push(...optionalColumnNames);

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(fileBlob, 'template.xlsx');
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUploadClick = () => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileData = event.target.result;
        const workbook = XLSX.read(fileData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Skip header (first row) and send the rest of the data for Firestore update
        const rows = data.slice(1); 
        handleFileUpload(rows);
      };
      reader.readAsBinaryString(selectedFile);
    } else {
      alert('Please select a file to upload.');
    }
  };

  return (
    <Modal 
      open={uploadModalOpen}
      onClose={handleCloseUploadModal}
      aria-labelledby="upload-modal-title"
      aria-describedby="upload-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}>
        <Typography id="upload-modal-title" variant="h6" component="h2">
          Select fields to customize your template. Download, fill it out, and upload.
        </Typography>

        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked disabled />}
            label="Username"
          />
          <FormControlLabel
            control={<Checkbox checked disabled />}
            label="Origin"
          />
          <FormControlLabel
            control={<Checkbox checked disabled />}
            label="Destination"
          />
          <FormControlLabel
            control={<Checkbox name="originBuilding" checked={optionalColumns.originBuilding} onChange={handleCheckboxChange} />}
            label="Origin Building"
          />
          <FormControlLabel
            control={<Checkbox name="originFloor" checked={optionalColumns.originFloor} onChange={handleCheckboxChange} />}
            label="Origin Floor"
          />
          <FormControlLabel
            control={<Checkbox name="destinationBuilding" checked={optionalColumns.destinationBuilding} onChange={handleCheckboxChange} />}
            label="Destination Building"
          />
          <FormControlLabel
            control={<Checkbox name="destinationFloor" checked={optionalColumns.destinationFloor} onChange={handleCheckboxChange} />}
            label="Destination Floor"
          />
          <FormControlLabel
            control={<Checkbox name="notes" checked={optionalColumns.notes} onChange={handleCheckboxChange} />}
            label="Notes"
          />
        </FormGroup>

        <Button onClick={createAndDownloadTemplate} variant="contained" sx={{ mt: 2 }}>
          Download Template
        </Button>

        <input
          type="file"
          onChange={handleFileChange}
          style={{ marginTop: '20px', marginBottom: '20px' }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button onClick={handleCloseUploadModal} variant="outlined">Cancel</Button>
          <Button onClick={handleFileUploadClick} variant="contained">Upload</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default UploadModal;
