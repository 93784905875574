import React from 'react';
import { Typography, Paper } from '@mui/material';

function Title({ title }) {
    return (
      <Paper elevation={3} sx={{ padding: 2, marginBottom: 2, backgroundColor: '#1976d2', color: 'white' }}>
          <Typography variant="h4" textAlign="center">{title}</Typography>
      </Paper>
    );
}

export default Title;
