import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const BooleanField = ({ label, condition, trueLabel, falseLabel }) => (
  <Typography variant="body2" sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
    {label}: <strong>{condition ? trueLabel : falseLabel}</strong>
  </Typography>
);

const Monitors = ({ monitors }) => (
  <>
    {Object.entries(monitors || {}).map(([key, monitor], index) => (
      <Grid item xs={12} sm={6} md={4} key={index}>
        <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
          <Typography variant="subtitle2" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' }, fontWeight: 'bold' }}>
            {`Monitor #${index + 1}${monitor.brand ? ` - ${monitor.brand}` : ''}`}
          </Typography>
          <BooleanField label="Orientation" condition={monitor.vertical} trueLabel="Vertical" falseLabel="Horizontal" />
          <BooleanField label="Arm Mount" condition={monitor.onArm} trueLabel="Yes" falseLabel="No" />
          <BooleanField label="Functional" condition={monitor.notFunctional} trueLabel="No" falseLabel="Yes" />
        </Paper>
      </Grid>
    ))}
  </>
);

export default Monitors;
