import CircularProgress from '@mui/material/CircularProgress';
import { Container } from '@mui/system';
import Box from '@mui/material/Box';

export default function AuthLoading() {
  return (
    <Container maxWidth="md">
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    </Container>
  );
}