import { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from '@mui/material';
import { signOut } from '../../store/actions/authActions';

class SignOut extends Component {
  handleClick = async(e) => {
    e.preventDefault();
    this.props.signOut();
  }
  render() {
    return (
      <Button onClick={this.handleClick}> Sign Out </Button>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(SignOut);

