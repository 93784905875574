import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const fbConfig = {
  apiKey: "AIzaSyAIdnrSt5djc69QUz2Kjovvpa5MeIUAQaQ",
  authDomain: "mmatablet-11c34.firebaseapp.com",
  databaseURL: "https://mmatablet-11c34.firebaseio.com",
  projectId: "mmatablet-11c34",
  storageBucket: "mmatablet-11c34.appspot.com",
  messagingSenderId: "870744547567",
  appId: "1:870744547567:web:b12d87e94bf47865167b1a"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(fbConfig);
}

const firestore = firebase.firestore();
const auth = firebase.auth();

if (window.location.hostname === 'localhost') {
  firestore.useEmulator('localhost', 8000);
  auth.useEmulator('http://localhost:9099/');
}

export { firebase, firestore, auth, fbConfig };
