import { useParams } from "react-router-dom";
import ProjectOLD from './ProjectOLD';

function ProjectParams() {
  const params = useParams();
  return (
    <ProjectOLD params={params} />
  )
}

export default ProjectParams;