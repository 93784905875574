import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, useFirestore } from 'react-redux-firebase';
import HamburgerMenu from './HamburgerMenu';
import ProjectAnalyticsThin from '../project/ProjectAnalyticsThin';
import Project from '../project/Project';
import { Container, Box } from '@mui/material';
import SelectClientNotice from './SelectClientNotice';
import Title from '../project/Title';
import CreateProject from '../project/CreateProject';

const Dashboard = ({ auth, profile, configuration }) => {
  const [workstations, setWorkstations] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [customTitle, setCustomTitle] = useState(null);
  const [url, setURL] = useState(null);
  const [refLocation, setRefLocation] = useState(null);
  const firestore = useFirestore();

  // Effect to listen for workstations changes in real-time
  useEffect(() => {
    if (refLocation) {
      const workstationsRef = firestore.collection(`${refLocation}/workstations`);
      const unsubscribe = workstationsRef.onSnapshot((snapshot) => {
        const workstationsData = snapshot.docs.map((doc) => doc.data());
        setWorkstations(workstationsData);
      });

      // Cleanup listener on component unmount or when refLocation changes
      return () => unsubscribe();
    }
  }, [refLocation, firestore]);

  let content;

  if (!customTitle) {
    content = <SelectClientNotice />;
  } else if (selectedProject) {
    content = (
      <Project
        auth={auth}
        title={customTitle}
        workstations={workstations}
        url={url}
        refLocation={refLocation}
        role={profile.role}
      />
    );
  } else if (workstations.length > 0) {
    content = (
      <>
        <Title title={customTitle} />
        <ProjectAnalyticsThin workstations={workstations} />
      </>
    );
  } else if (auth.uid && (profile.role === 'admin' || profile.role === 'supervisor') && selectedClient != 'all') {
    content = (
      <>
        <Title title={customTitle} />
        <CreateProject selectedClient={selectedClient} />
      </>
    );
  } else {
    content = <Title title={customTitle} />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column', // For small screens
          sm: 'row', // For medium and larger screens
        },
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'relative',zIndex: 1102, height: { xs: 'auto', sm: '100%' } }}>
        <HamburgerMenu
          auth={auth}
          profile={profile}
          setWorkstations={setWorkstations}
          setSelectedClient={setSelectedClient}
          setSelectedProject={setSelectedProject}
          setCustomTitle={setCustomTitle}
          setURL={setURL}
          setRefLocation={setRefLocation}
          selectedClient={selectedClient}
          configuration={configuration}
        />
      </Box>
      <Container maxWidth="md">
        {content}
      </Container>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  configuration: state.firestore.data.configuration,
});

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    return [
      {
        collection: 'projects',
        doc: 'configuration',
        storeAs: 'configuration'
      },
    ];
  })
)(Dashboard);
