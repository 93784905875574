import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { FaCircle } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const Issues = ({ issues }) => {
  const hasClientIssues = Object.values(issues || {}).filter(issue => issue.clientIssue).length > 0;

  return hasClientIssues ? (
    <Grid item xs={12}>
      <Paper variant="outlined" sx={{ padding: 2, marginTop: 2 }}>
        <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>
          Issues
        </Typography>
        {Object.entries(issues || {}).map(([id, issue]) => (
          <Grid container spacing={1} key={id} sx={{ marginTop: 1 }}>
            <Grid item xs={12}>
              <IconContext.Provider value={{ color: issue.resolvedBy ? 'green' : 'red' }}>
                <FaCircle />
              </IconContext.Provider>
              {" " + issue.issue}
            </Grid>
          </Grid>
        ))}
      </Paper>
    </Grid>
  ) : null;
};

export default Issues;
