import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const createProject = (project) => {
    return async (dispatch, getState, { getFirebase, getFirestore }) => {
        const firestore = getFirestore();

        try {
            // Combine the date and time to create a proper DateTime object
            const combinedDateTimeString = `${project.date}T${project.startTime}`;
            const dateTime = moment(combinedDateTimeString, "YYYY-MM-DDTHH:mm").toDate();

            const year = moment(project.date, "YYYY-MM-DD").format('YYYY');
            const month = moment(project.date, "YYYY-MM-DD").format('MMMM');

            // Create a unique project ID
            const projectId = uuidv4();
            const projectPath = `projects/${year}/${month}/${projectId}`;

            // Reference to the new project document in Firestore
            const projectDocRef = firestore.doc(projectPath);

            // Set the new project data in Firestore
            await projectDocRef.set({
                client: project.client,
                date:dateTime,
                itemType: 0,
                linkedProjects: [],
                name: project.name,
                public: false,
            });

            // Fetch the newly created project data
            const projectDoc = await projectDocRef.get();
            if (!projectDoc.exists) {
                throw new Error(`Project with path ${projectPath} not found`);
            }
            const projectData = projectDoc.data();
            const projectClientID = projectData.client;

            // Reference to the client's document in Firestore
            const clientDocRef = firestore.collection('clients').doc(project.client);
            const clientDoc = await clientDocRef.get();
            if (!clientDoc.exists) {
                throw new Error(`Client with ID ${project.client} not found`);
            }

            // Validate that the project's client ID matches the one in the client document
            if (project.client !== projectClientID) {
                throw new Error(`ClientID for project (${projectClientID}) doesn't match clientID for client (${project.client})`);
            }

            // Prepare the final project info to store within the client's 'projects' subcollection
            const projectInfo = {
                date: projectData.date,
                name: projectData.name,
                refLocation: projectDocRef
            };

            // Add or update the project document within the client's 'projects' collection
            const projectCollectionRef = clientDocRef.collection('projects');
            await projectCollectionRef.doc(projectId).set(projectInfo, { merge: true });

            // Dispatch success action
            dispatch({ type: 'CREATE_PROJECT', project });
        } catch (err) {
            // Dispatch error action if something goes wrong
            dispatch({ type: 'CREATE_PROJECT_ERROR', err });
        }
    };
};
