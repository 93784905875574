import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const Peripherals = ({ peripherals }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Paper elevation={2} sx={{ padding: 2, height: '100%' }}>
      <Typography variant="h6" textAlign="center" sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem', md: '1.25rem' }, fontWeight: 'bold' }}>
        Peripherals
      </Typography>
      {['Headsets', 'Keyboards', 'Mice', 'Phones', 'Speakers'].map((label) => (
        <Typography key={label} sx={{ fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1rem' } }}>
          {`${label}: ${Object.keys(peripherals?.[label.toLowerCase()] || {}).length}`}
        </Typography>
      ))}
    </Paper>
  </Grid>
);

export default Peripherals;
