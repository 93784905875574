export const signIn = (credentials) => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' });
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err });
    });
  }
}

export const signOut = () => {
  return (dispatch, getState, {getFirebase}) => {
    const firebase = getFirebase();

    firebase.auth().signOut().then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS' })
    }).catch((err) => {
      dispatch({ type: 'SIGNOUT_ERROR', err });
    });
  }
}

export const signUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {

      if (newUser.password !== newUser.repeatPassword) {
          dispatch({ type: 'PASSWORD_ERROR', err: "Passwords do not match" })
      } else {
          const firebase = getFirebase();
          const firestore = getFirestore();

          firebase.auth().createUserWithEmailAndPassword(
              newUser.email,
              newUser.password
          ).then((resp) => {
              return firestore.collection('users').doc(resp.user.uid).set({
                  email: newUser.email,
                  phone: newUser.phone,
                  firstName: newUser.firstName,
                  lastName: newUser.lastName,
                  initials: newUser.firstName[0] + newUser.lastName[0],
                  role: "none",
                  clientScope: [],
                  projectScope: [],
                  vaccineStatus: true,
                  notifications: {
                                      email: {
                                          confirmedForProject: true,
                                          newProjects: true,
                                          projectUpdated: true,
                                          supervisorComment: true,
                                          leadComment: true,
                                      },

                                      phone: {
                                          confirmedForProject: false,
                                          newProjects: false,
                                          projectUpdated: false,
                                          supervisorComment: false,
                                          leadComment: false,
                                      }
                  },
                  writeups: {},
                  certifications: [],
                  experience: [],
              })
          }).then(() => {
              dispatch({ type: 'SIGNUP_SUCCESS' })
          }).catch(err => {
              dispatch({ type: 'SIGNUP_ERROR', err })
          })
      }
  }
}

export const resetPasswordEmail = (email) => {
  return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();

      firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
          dispatch({ type: 'EMAIL_SUCCESS', message: "Password reset email has been successfully sent, check your inbox" });
      }).catch((err) => {
          dispatch({ type: 'EMAIL_ERROR', err });
      });
  }
}